@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,600;0,700;1,300&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/leaflet.css");

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  background: #f1f1fa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.divider {
  position: relative;
  text-align: center;
  color: #bbbbbb;
}

.divider::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  height: 1px;
  width: 100%;
  background: #bbbbbb;
}
.leaflet-popup-content-wrapper {
  border-radius: 4px !important;
}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important;
}

/* hide routing controls */
.leaflet-control-container > .leaflet-right {
  display: none;
}

.scrollable::-webkit-scrollbar {
  width: 4px;
}

.scrollable::-webkit-scrollbar-track {
  background: #1c1c53;
}

.scrollable::-webkit-scrollbar-thumb {
  background: #fecf0a;
}

.scrollable:hover::-webkit-scrollbar-thumb {
  background: #fecf0a;
}

.scrollable::-webkit-scrollbar-thumb:hover {
  background: #fecf0a;
}

.rc-dropdown {
  z-index: 10000 !important;
}

*::-webkit-scrollbar {
  top: 23px;
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background: #f5f5f5;
}

*:hover::-webkit-scrollbar-thumb {
  background: #e6e6e6;
}

*::-webkit-scrollbar-thumb:hover {
  background: #bebebe;
}
